import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import { Container, Row, Col } from "reactstrap"
import ArrowTop from "../images/icons/arrow-top.svg"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you!" slug="thanks" />
    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="12" className="super-margin">
            <h1>
              <span className="gray">Thank you!</span>
              <br />
              We'll get in touch soon.
            </h1>
          </Col>
        </Row>
      </Container>
    </Row>
    <Row className="yellow-container">
      <Container>
        <Row className="pt-5 pb-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h3>View other pages</h3>
            <Row className="no-gutters mt-5 mb-5">
              <Col md="7" className="long">
                <a href="/">
                  <u className="large-p">Home Page</u>
                  <ArrowTop
                    className="icon"
                    style={{ margin: "-10px 0 0 10px" }}
                  />
                </a>
              </Col>
              <Col md="7" className="long">
                <a href="/contact">
                  <u className="large-p">Contact</u>
                  <ArrowTop
                    className="icon"
                    style={{ margin: "-10px 0 0 10px" }}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Row>
  </Layout>
)

export default ThankYouPage
